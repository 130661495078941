import {fetchSafeDocumentFragment} from '@github-ui/fetch-utils'
import {on} from 'delegated-events'
import {addUrlToHistoryStack} from '@github-ui/history'
import {ready} from '@github-ui/document-ready'

// If the page parameter is provided, the page number in the
// container's src URL will be updated to the provided number.
// Otherwise the src URL will be used as-is.
//
// This function is exported so that it can be tested.
export async function updateAdvancedSecurityEntity(container: HTMLElement, page?: number) {
  const showOnError = container.querySelector<HTMLElement>('[data-show-on-error]')
  const loadingElement = container.querySelector<HTMLElement>('[data-loading]')

  const dataContainer = container.querySelector<HTMLElement>('[data-content-container]')
  if (dataContainer == null) {
    handleErrorState(showOnError, loadingElement, dataContainer)
    return
  }

  let src = container.getAttribute('data-src')
  if (src == null) {
    handleErrorState(showOnError, loadingElement, dataContainer)
    return
  }

  // Update the page number if one has been explicitly passed in
  // and hide the pager, replacing it with a spinner.
  //
  // There's no javascript to handle showing the pager again and
  // hiding the paging spinner, since the container's entire contents
  // - including the pager - are replaced with the response data (or
  // error message) anyway!
  if (page !== undefined) {
    const srcURL = new URL(src, window.origin)

    srcURL.pathname = srcURL.pathname.replace(/\d+$/, page.toString())

    const newSrc = srcURL.toString()

    if (src === newSrc) {
      // another container has triggered a statechange but we are still on the correct page and do not need
      // to fetch again
      return
    }

    src = newSrc

    const pager = container.querySelector<HTMLElement>('.js-advanced-security-entities-pagination')
    if (pager) {
      pager.hidden = true
    }
    const pagerLoadingElement = container.querySelector('[data-pager-loading]')
    if (pagerLoadingElement) {
      pagerLoadingElement.removeAttribute('hidden')
    }
    const otherHideables = container.querySelectorAll<HTMLElement>('[data-hide-when-paging]')
    for (const hideable of otherHideables) {
      hideable.hidden = true
    }
  } else {
    if (dataContainer.children.length > 0) {
      // another pjax container has reloaded but we are already showing a page and we do not need
      // to fetch again
      return
    }
  }

  let entityList
  try {
    entityList = await fetchSafeDocumentFragment(document, src)
  } catch {
    handleErrorState(showOnError, loadingElement, dataContainer)
    return
  }
  if (!entityList) {
    handleErrorState(showOnError, loadingElement, dataContainer)
    return
  }

  if (loadingElement) {
    loadingElement.hidden = true
  }

  dataContainer.textContent = ''
  dataContainer.appendChild(entityList)
}

function findContainerWithRef(refToFind: string): HTMLDivElement | null {
  const containers = document.querySelectorAll<HTMLDivElement>('.js-ghas-entity-list-container')
  if (containers == null || containers.length === 0) {
    return null
  }

  for (const container of containers) {
    const showOnError = container.querySelector<HTMLElement>('[data-show-on-error]')
    const loadingElement = container.querySelector<HTMLElement>('[data-loading]')
    const dataContainer = container.querySelector<HTMLElement>('[data-content-container]')

    const ref = container.getAttribute('data-ref')
    if (ref == null) {
      handleErrorState(showOnError, loadingElement, dataContainer)
      return null
    }

    // found the container worth updating
    if (ref === refToFind) {
      return container
    }
  }

  return null
}

export async function retrieveAdvancedSecurityEntities(page?: number) {
  const containers = document.querySelectorAll<HTMLDivElement>('.js-ghas-entity-list-container')
  if (containers == null || containers.length === 0) {
    return
  }

  for (const container of containers) {
    await updateAdvancedSecurityEntity(container, page)
  }
}

function handleErrorState(
  showOnError: HTMLElement | null,
  loadingElement: HTMLElement | null,
  dataContainer: HTMLElement | null,
) {
  if (showOnError) {
    showOnError.hidden = false
  }
  if (loadingElement) {
    loadingElement.hidden = true
  }
  if (dataContainer) {
    dataContainer.textContent = ''
  }
}

// retrieve the repo/org list on page load
;(async () => {
  await ready
  retrieveAdvancedSecurityEntities()
})()

// switching between settings pages is handled by turbo, so doesn't trigger a
// full page reload. Listen for turbo:load to handle this
document.addEventListener('turbo:load', function () {
  retrieveAdvancedSecurityEntities()
})

window.addEventListener('popstate', function (event) {
  if (event.state === null) {
    // the user has popped through all the states and gone back to the first page
    // this will not trigger a statechange event so we must reload it explicitly
    retrieveAdvancedSecurityEntities(1)
  }
})

// reload entries on a push/pop state change
window.addEventListener('statechange', function () {
  const params = new URLSearchParams(document.location.search)
  const trackedParams = [
    'advanced_security_entities_page',
    'advanced_security_users_page',
    'code_security_entities_page',
    'secret_security_entities_page',
  ]

  for (const param of trackedParams) {
    const pageNumber = parseInt(params.get(param) || '')
    if (isNaN(pageNumber)) {
      continue
    }

    const container = findContainerWithRef(param)
    if (container == null) {
      continue
    }

    updateAdvancedSecurityEntity(container, pageNumber)
  }
})

on('click', '.js-advanced-security-entities-pagination a', function (event) {
  if (event == null || event.target == null) {
    return
  }
  const href = (event.target as HTMLAnchorElement).href

  const hrefURL = new URL(href, window.origin)

  const currentParams = new URLSearchParams(document.location.search)

  for (const [key, value] of currentParams) {
    if (!hrefURL.searchParams.has(key)) {
      hrefURL.searchParams.append(key, value)
    }
  }

  addUrlToHistoryStack(hrefURL.toString())

  event.preventDefault()
})
