import '../codespaces/default-location-setting-element'
import {TemplateInstance} from '@github/template-parts'
import {on} from 'delegated-events'
import {parseHTML} from '@github-ui/parse-html'
import {replaceAndRefocus} from '../codespaces'
import type {SelectPanelExperimentalElement} from '../../../../components/primer/experimental/select-panel-element'

on('itemActivated', '#settings_user_codespaces_update_dotfiles_repo', () => {
  const form = document.querySelector<HTMLFormElement>('.js-codespaces-update-dotfiles-repo-form')
  if (!form) return

  submitUserSettingsForm(form)
})

on('itemActivated', '#new-codespaces-secret-repository-select-panel', event => {
  const panel = event.target as SelectPanelExperimentalElement
  const count = panel.selectedItems?.length
  const info = panel.parentElement?.querySelector('[data-selected-repos-info]')

  if (info) {
    ;(info.querySelector('[data-target="selected-repositories.plural"]') as HTMLElement).hidden = count === 1
    ;(info.querySelector('[data-target="selected-repositories.singular"]') as HTMLElement).hidden = count !== 1
    info.querySelector('[data-target="selected-repositories.count"]')!.textContent = count.toString()
    ;(info.querySelector('[data-target="selected-repositories.active-warning"]') as HTMLElement).hidden = count !== 0
  }
  const repositoryContainer = panel.parentElement?.querySelector('[data-target="selected-repositories.repositories"]')

  repositoryContainer!.textContent = ''

  for (const item of panel.selectedItems) {
    const repoControl = createActionListItem(item.label as string, item.value as string)
    repositoryContainer!.append(repoControl)
  }
})

on('click', '#trusted_repositories_repo_selection_submit_button', () => {
  const form = document.querySelector<HTMLFormElement>('.js-codespaces-update-trusted-repositories-select-form')
  if (!form) return

  const panel = document.querySelector('#trusted_repositories_repo_selection') as SelectPanelExperimentalElement
  const inputContainer = form.querySelector('[data-target="add-repo-info"]')!

  for (const item of panel.selectedItems) {
    const hiddenInput = document.createElement('input')
    hiddenInput.type = 'hidden'
    hiddenInput.name = 'repo[]'
    hiddenInput.value = item.value as string
    inputContainer.append(hiddenInput)
  }

  submitUserSettingsForm(form)
})

on('click', '#trusted_repositories_repo_selection_cancel_button', () => {
  const panel = document.querySelector('#trusted_repositories_repo_selection') as SelectPanelExperimentalElement
  panel.dialog.close()
})

on('submit', '.js-codespaces-update-dotfiles-repo-form', async event => {
  const form = event.currentTarget as HTMLFormElement
  event.preventDefault()
  submitUserSettingsForm(form)
})

const submitUserSettingsForm = async (form: HTMLFormElement) => {
  const response = await fetch(form.action, {
    method: form.method,
    body: new FormData(form),
    headers: {Accept: 'text/fragment+html'},
  })

  // Show an error message (if request fails)
  if (response.status >= 400) {
    // eslint-disable-next-line i18n-text/no-en
    const message = 'An unknown error occurred.'
    const template = document.querySelector<HTMLTemplateElement>('template.js-flash-template')!
    template.after(new TemplateInstance(template, {className: 'flash-error', message}))
  } else {
    const target = form.closest('[data-replace-remote-form-target]') as HTMLElement

    const partial = parseHTML(document, await response.text())

    replaceAndRefocus(target, partial)
  }
}

on('click', '.js-codespaces-user-secrets-form input[name=repo]', async event => {
  const input = event.currentTarget as HTMLInputElement
  const form = input.closest<HTMLElement>('form')!
  if (!form.querySelector(`[name="codespaces_user_secret[repository_ids][]"][value="${input.value}"]`)) {
    // Create and insert the repository control
    const repoName = input.closest('.select-menu-item-text')!.querySelector('[data-menu-button-contents]')!.textContent
    if (repoName && input.value) {
      const repoControl = createRepositoryRow(repoName, input.value)
      form.querySelector('[data-target="selected-repositories.repositories"]')!.append(repoControl)
    }
    // Update the count
    updateSelectedRepositoryCount(event)
  }
  input.closest('details')!.removeAttribute('open')
  event.preventDefault()
})

on(
  'change',
  '.js-codespaces-user-secrets-form input[name="codespaces_user_secret[repository_ids][]"]',
  updateSelectedRepositoryCount,
)

function createActionListItem(repositoryName: string, repositoryId: string) {
  const repoControl = document.createElement('li')
  repoControl.className = 'ActionListItem'
  repoControl.setAttribute('data-targets', 'action-list.items')
  const repoIdInput = document.createElement('input')
  repoIdInput.className = ''
  repoIdInput.type = 'hidden'
  repoIdInput.name = 'codespaces_user_secret[repository_ids][]'
  repoIdInput.value = repositoryId
  repoControl.append(repoIdInput)
  const button = document.createElement('button')
  button.type = 'button'
  button.className = 'ActionListContent'
  const span = document.createElement('span')
  span.className = 'ActionListItem-label'
  span.textContent = repositoryName
  button.append(span)
  repoControl.append(button)
  return repoControl
}

function createRepositoryRow(repositoryName: string, repositoryId: string) {
  const repoControl = document.createElement('li')
  repoControl.className = 'css-truncate d-flex flex-items-center width-full'
  const checkbox = document.createElement('input')
  checkbox.className = 'repository-item-checkbox js-repository-item-checkbox mx-2 position-absolute'
  checkbox.type = 'checkbox'
  checkbox.name = 'codespaces_user_secret[repository_ids][]'
  checkbox.value = repositoryId
  checkbox.id = `user-secret-repo-${repositoryId}`
  checkbox.checked = true
  repoControl.append(checkbox)
  const label = document.createElement('label')
  label.className = 'repository-item-name d-flex flex-items-center width-full color-fg-default py-1 pr-1 pl-5'
  label.setAttribute('for', `user-secret-repo-${repositoryId}`)
  label.title = repositoryName
  label.textContent = repositoryName
  repoControl.append(label)

  return repoControl
}

function updateSelectedRepositoryCount(event: Event) {
  const input = event.currentTarget as HTMLInputElement
  const form = input.closest<HTMLElement>('form')!
  // Update the count
  const count = form.querySelectorAll('[name="codespaces_user_secret[repository_ids][]"]:checked').length
  form.querySelector<HTMLElement>('[data-target="selected-repositories.plural"]')!.hidden = count === 1
  form.querySelector<HTMLElement>('[data-target="selected-repositories.singular"]')!.hidden = count !== 1
  form.querySelector<HTMLElement>('[data-target="selected-repositories.count"]')!.textContent = String(count)
  form.querySelector<HTMLElement>('[data-target="selected-repositories.active-warning"]')!.hidden = count !== 0
}

// We're using this instead of a `pattern=` attribute on this input since using
// a pattern doesn't support case-insensitivity
on('focusout', '.js-codespaces-user-secrets-form input[name="codespaces_user_secret[name]"]', event => {
  const field = event.currentTarget as HTMLInputElement
  const value = field.value

  const regex = new RegExp('^(?!github).+', 'i')
  const formGroup = field.closest('div.form-group')!

  if (regex.test(value)) {
    field.setCustomValidity('')
    formGroup.classList.remove('errored')
    field.removeAttribute('aria-describedby')
  } else {
    // This string is never surfaced to the UI
    // eslint-disable-next-line i18n-text/no-en
    field.setCustomValidity('Secret names cannot start with "GITHUB"')
    field.setAttribute('aria-describedby', 'codespaces-secret-name-error')
    formGroup.classList.add('errored')
  }
  const form = field.closest<HTMLFormElement>('form')!
  form.dispatchEvent(new Event('change'))
})

on('focusout', '.js-codespaces-user-secrets-form textarea[name="secret_value"]', event => {
  const field = event.currentTarget as HTMLInputElement

  const value = field.value

  const formGroup = field.closest('div.form-group')!
  if (value.trim() === '') {
    formGroup.classList.add('errored')
    field.setAttribute('aria-describedby', 'codespaces-secret-value-error')
  } else {
    formGroup.classList.remove('errored')
    field.removeAttribute('aria-describedby')
  }
})
