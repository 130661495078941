// PATs v2: editing and creation
import {observe} from '@github/selector-observer'
import {on} from 'delegated-events'

import {toggleMetadataSelectionMenu} from './integrations'
import {PermissionType, RepoSelectionType, updateElementText} from './personal-access-tokens'
import {updatePermissionSummary, updateRepositoryCount, updateExpiration} from './personal-access-token-summary'

function resetPermissions() {
  const actionLists = document.querySelectorAll<HTMLElement>(`.js-integration-permissions-selector action-list`)

  for (const actionList of actionLists) {
    const input = actionList.querySelector<HTMLInputElement>('[data-list-inputs] [name^="integration"]')!
    const noAccessButton = actionList.querySelector<HTMLButtonElement>(`[data-permission=${RepoSelectionType.None}]`)!

    input.value = RepoSelectionType.None
    noAccessButton.click()

    resetAccessButton(noAccessButton)
  }

  toggleMetadataSelectionMenu()
  setInitialValues()
}

function resetAccessButton(button: HTMLButtonElement) {
  const row = button.closest<HTMLElement>('.js-list-group-item')

  if (row) {
    const actionMenu: HTMLElement = row.querySelector('#action-selection-list-menu')!
    const actionMenuShowButton = row.querySelector<HTMLButtonElement>('.js-action-selection-list-menu-button')!
    const actionMenuTooltip: HTMLElement = actionMenu.querySelector('[id^=tooltip]')!

    actionMenuShowButton.ariaDisabled = 'false'
    actionMenuShowButton.classList.remove('Button--inactive')
    if (actionMenuTooltip) actionMenuTooltip.style.display = 'none'
  }
}

function toggleRepoPermissionVisibility() {
  const selectionType = document.querySelector<HTMLInputElement>('.js-installation-repositories-radio:checked')!.value
  const permissionSection: HTMLElement = document.querySelector<HTMLElement>(
    '.js-integration-permissions-selector .js-permissions-selector-visibility-toggle',
  )!

  if (selectionType === RepoSelectionType.None) {
    permissionSection.hidden = true
    updateElementText(`#token-summary [data-token-summary="repository-total-permission-count"]`, String(0))
  } else {
    permissionSection.hidden = false
    updatePermissionSummary('repository')
  }
  togglePermissionsBorder()
}

function togglePermissionsBorder() {
  const rows = document.querySelectorAll('.js-integration-permissions-selector .js-target-row')
  if (rows.length > 1) {
    const permissionSection = document.querySelector<HTMLElement>(
      '.js-integration-permissions-selector .js-permissions-selector-visibility-toggle',
    )!
    if (permissionSection.hidden) {
      rows[1]?.classList.add('border-top-0', 'pt-1')
    } else {
      rows[1]?.classList.remove('border-top-0', 'pt-1')
    }
  }
}

function displayGrantForm() {
  const grantForm = document.querySelector<HTMLElement>('#js-grant-form')!
  const grantSummary = document.querySelector<HTMLElement>('#js-grant-summary')!
  grantSummary.hidden = true
  grantForm.hidden = false

  const formActions = document.querySelectorAll<HTMLElement>('.js-grant-form-action')
  for (const action of formActions) {
    action.hidden = false
  }

  const summaryActions = document.querySelectorAll<HTMLElement>('.js-grant-summary-action')
  for (const action of summaryActions) {
    action.hidden = true
  }
}

function displayGrantSummary(event: Event) {
  const target = event.currentTarget as HTMLElement

  if (target.classList.contains('js-grant-reload')) {
    return window.location.reload()
  }

  const grantForm = document.querySelector<HTMLElement>('#js-grant-form')!
  const grantSummary = document.querySelector<HTMLElement>('#js-grant-summary')!
  grantForm.hidden = true
  grantSummary.hidden = false

  const summaryActions = document.querySelectorAll<HTMLElement>('.js-grant-summary-action')
  for (const action of summaryActions) {
    action.hidden = false
  }

  const formActions = document.querySelectorAll<HTMLElement>('.js-grant-form-action')
  for (const action of formActions) {
    action.hidden = true
  }
}

function displayTokenForm() {
  const displayFields = document.querySelectorAll<HTMLElement>('.js-token-display-field')
  for (const field of displayFields) {
    field.hidden = true
  }

  const formFields = document.querySelectorAll<HTMLElement>('.js-token-form-field')
  for (const field of formFields) {
    field.hidden = false
  }
}

function hideTokenForm() {
  const formFields = document.querySelectorAll<HTMLElement>('.js-token-form-field')
  for (const field of formFields) {
    field.hidden = true
  }

  const displayFields = document.querySelectorAll<HTMLElement>('.js-token-display-field')
  for (const field of displayFields) {
    field.hidden = false
  }
}

function setInitialValues() {
  for (const permissionType of Object.values(PermissionType)) {
    updatePermissionSummary(permissionType)
  }
  updateRepositoryCount()
  togglePermissionsBorder()

  // set initial state of collapsible containers to closed
  // includes permission lists and summaries
  for (const details of document.querySelectorAll<HTMLDivElement>('.js-collapsible-container')) {
    details.removeAttribute('open')
  }
}

function toggleSelectedLabel(selectedItem: Element) {
  const row = selectedItem.closest<HTMLElement>('.js-list-group-item')
  const itemButton = selectedItem.querySelector<HTMLButtonElement>('button')
  if (!row || !itemButton) return

  const noAccessSelected = itemButton.getAttribute('data-permission') === 'none'

  const selectedLabel = row.querySelector<HTMLElement>('.js-selected-label')
  const mandatoryLabel = row.querySelector<HTMLElement>('.js-mandatory-label')

  if (selectedLabel) {
    selectedLabel.hidden = noAccessSelected

    if (mandatoryLabel && noAccessSelected) {
      mandatoryLabel.hidden = true
    }
  }
}

function addErrorToPrimerTextField(input: HTMLInputElement, errorMessage: string) {
  const field = input.closest('primer-text-field')
  if (field) {
    input.setAttribute('invalid', 'true')
    input.setAttribute('aria-invalid', 'true')
    field.querySelector<HTMLElement>('.FormControl-inlineValidation')!.hidden = false
    field.querySelector<HTMLElement>('[data-target="primer-text-field.validationMessageElement"]')!.textContent =
      errorMessage

    field.querySelector<HTMLElement>('span[data-target="primer-text-field.validationSuccessIcon"]')!.hidden = true
  }
}

function clearValidationErrors() {
  const customExpirationDateError = document.querySelector<HTMLElement>('.js-custom-expiration-date-error')
  if (customExpirationDateError) {
    const expirationDate = document.getElementById('user_programmatic_access_custom_expires_at') as HTMLInputElement
    customExpirationDateError.hidden = true
    expirationDate.removeAttribute('aria-invalid')
    expirationDate.removeAttribute('invalid')
  }

  const minRepositoryError = document.querySelector<HTMLElement>('.js-min-repository-error')
  if (minRepositoryError) minRepositoryError.hidden = true
}

function validateTokenName() {
  const tokenName = document.getElementById('user_programmatic_access_name') as HTMLInputElement

  if (tokenName.value.trim() === '') {
    // eslint-disable-next-line i18n-text/no-en
    addErrorToPrimerTextField(tokenName, "Name can't be blank")
    return {element: tokenName}
  }

  return null
}

function validateCustomExpiration() {
  const customExpirationSelected =
    document
      .querySelector('action-menu.js-new-default-token-expiration-select button[aria-checked=true]')
      ?.getAttribute('data-value') === 'custom'

  if (customExpirationSelected) {
    const expirationDate = document.getElementById('user_programmatic_access_custom_expires_at') as HTMLInputElement

    if (expirationDate.value.trim() === '') {
      expirationDate.setAttribute('invalid', 'true')
      expirationDate.setAttribute('aria-invalid', 'true')
      document.querySelector<HTMLElement>('.js-custom-expiration-date-error')!.hidden = false
      return {element: expirationDate}
    }
  }

  return null
}

function validateRepositoryAccess() {
  const repositoryAccessElement = document.getElementById('install_target_selected')
  if (!repositoryAccessElement) return null
  const repositoryAccess = repositoryAccessElement as HTMLInputElement

  if (repositoryAccess.checked) {
    const selectedRepositories = document.querySelectorAll<HTMLInputElement>('.js-selected-repository-field')

    if (selectedRepositories.length === 0) {
      const minRepositoryError = document.querySelector<HTMLElement>('.js-min-repository-error')
      if (minRepositoryError) {
        minRepositoryError.hidden = false
      }
      return {element: repositoryAccess}
    }
  }

  return null
}

observe('.js-user-programmatic-access-form .js-integrations-install-repo-selection', el => {
  // observes when repository selections are added to the DOM
  // handles the case when user toggles back to select repositories displaying previous selections
  const selectedRepoListObserver = new MutationObserver(updateRepositoryCount)
  // observe subtree from element that is never removed from the dom to catch all changes
  selectedRepoListObserver.observe(el, {childList: true, subtree: true})
})

// observes when permissions sections are changed (load, repo selection changes, target changes)
observe('.js-integration-permissions-selector', setInitialValues)

on('change', '.js-permissions-selector [id^=integration_permission_]', ({currentTarget}) => {
  const permissionType = currentTarget.getAttribute('data-resource-parent')!
  updatePermissionSummary(permissionType)
})

on('itemActivated', '#action-selection-list-menu', (event: CustomEvent) => {
  const selectedItem = event.detail.item as HTMLLIElement
  const itemButton = selectedItem.querySelector<HTMLButtonElement>('button')
  if (!itemButton) return

  const permissionType = itemButton.getAttribute('data-resource-parent')!
  updatePermissionSummary(permissionType)

  const isProgrammaticClick = itemButton.getAttribute('data-programmatic') === 'true'
  if (isProgrammaticClick) {
    itemButton.removeAttribute('data-programmatic')
  } else {
    toggleSelectedLabel(selectedItem)
  }
})

on('change', '.js-user-programmatic-access-form .js-installation-repositories-radio', () => {
  resetPermissions()
  toggleRepoPermissionVisibility()
})
on('change', '.js-user-programmatic-access-form #user_programmatic_access_custom_expires_at', updateExpiration)

on('click', '#js-token-action-edit', displayTokenForm)
on('click', '#js-token-action-cancel', hideTokenForm)
on('click', '#js-token-action-save', function ({currentTarget}) {
  currentTarget.classList.add('disabled')
  currentTarget.textContent = 'Updating'
})

on('click', '.js-toggle-grant-form', displayGrantForm)
on('click', '.js-toggle-grant-summary', displayGrantSummary)

on('turbo:submit-start', '.js-user-programmatic-access-form', function (event) {
  clearValidationErrors()

  // Validate all fields and collect errors
  const errors = [validateTokenName(), validateCustomExpiration(), validateRepositoryAccess()].filter(
    error => error !== null,
  )

  // If any errors, stop form submission and scroll to first error
  if (errors.length > 0) {
    const firstError = errors[0]
    if (firstError) {
      firstError.element.scrollIntoView({block: 'center', behavior: 'smooth'})
    }

    event.detail.formSubmission.stop()
  }
})
