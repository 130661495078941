import {controller, target} from '@github/catalyst'

@controller
export class SelectedItemCountElement extends HTMLElement {
  @target declare selectedCount: HTMLElement
  @target declare pluralItemsDescription: HTMLElement
  @target declare singularItemDescription: HTMLElement

  updateCount(count: number) {
    this.selectedCount.textContent = String(count)
    this.pluralItemsDescription.hidden = count === 1
    this.singularItemDescription.hidden = count !== 1
  }
}
