// PATs v2: Token summary display behavior

import {updateCollapsibleContainerStyles} from './personal-access-token-collapsible-containers'
import {PermissionType, RepoSelectionType, updateElementText} from './personal-access-tokens'

const PermissionSummarySelector = {
  Organization: '#js-organization-permission-summary',
  Repository: '#js-repository-permission-summary',
  User: '#js-user-permission-summary',
} as const

type PermissionSummarySelector = (typeof PermissionSummarySelector)[keyof typeof PermissionSummarySelector]

const CheckedPermissionsSelector = {
  Organization: '[data-organization-permissions]',
  Repository: '[data-repository-permissions]',
  User: '[data-user-permissions]',
  Business: '[data-business-permissions]',
} as const

type CheckedPermissionsSelector = (typeof CheckedPermissionsSelector)[keyof typeof CheckedPermissionsSelector]

export function updateRepositoryCount() {
  const repoSelectorRadio = document.querySelector<HTMLInputElement>('.js-installation-repositories-radio:checked')
  const selectionType = repoSelectorRadio ? repoSelectorRadio.value : RepoSelectionType.None

  if (selectionType === RepoSelectionType.Selected) {
    const repositoryCount = document.querySelectorAll<HTMLInputElement>('.js-selected-repository-field').length
    updateElementText('#token-summary [data-token-summary="repository-count"]', String(repositoryCount))
  } else {
    updateElementText('#token-summary [data-token-summary="repository-count"]', selectionType)
  }
}

export function updatePermissionSummary(permissionType: string) {
  const selectedPermissionElements = getSelectedPermissionElements(permissionType)

  let selectedCount = selectedPermissionElements.length
  let mandatoryCount = 0

  if (selectedCount > 0) {
    const mandatoryPermissionElements = getMandatoryPermissionElements(permissionType)
    mandatoryCount = mandatoryPermissionElements.length
    selectedCount -= mandatoryCount
  }

  if (selectedCount === 0 && mandatoryCount === 1) {
    selectedCount += mandatoryCount--
  }

  updatePermissionSummaryCounts(permissionType, selectedCount, mandatoryCount)
  togglePermissionContainerLabels(permissionType, selectedCount, mandatoryCount)

  const summaryContainer = getSummaryContainerElement(permissionType)

  // integrations pages exit here because they don't have the summary container
  if (!summaryContainer) return

  refreshPermissionSummary(summaryContainer, selectedPermissionElements)

  updateCollapsibleContainerStyles(summaryContainer)
}

function refreshPermissionSummary(
  summaryContainer: HTMLDivElement,
  selectedPermissionElements: NodeListOf<HTMLInputElement | HTMLButtonElement> | never[],
) {
  const listElement = summaryContainer.querySelector<HTMLUListElement>('ul')!

  // clear out the permission summary
  for (const li of listElement.querySelectorAll<HTMLLIElement>('li')) {
    if (li.getAttribute('data-permission-template') === 'false') listElement.removeChild(li)
  }

  for (const selectedElement of selectedPermissionElements) {
    appendPermissionSummary(listElement, selectedElement)
  }
}

export function updateExpiration() {
  const expirationField = document.querySelector<HTMLInputElement>(
    '.js-user-programmatic-access-form input[name="user_programmatic_access[default_expires_at]"]',
  )
  if (!expirationField) return
  const expirationValue = expirationField.value

  if (expirationValue === 'none') {
    updateElementText('#token-summary [data-token-summary="expiration"]', 'never.')
  } else if (expirationValue === 'custom') {
    const customDate = document.querySelector<HTMLInputElement>('#user_programmatic_access_custom_expires_at')!.value
    // Setting the time to midnight because otherwise the default of midnight UTC might output the prior date based on timezone offset
    const dateString = customDate ? formatDateString(new Date(`${customDate}T00:00:00`)) : 'an unselected custom date'
    updateElementText('#token-summary [data-token-summary="expiration"]', `on ${dateString}.`)
  } else {
    const dateString = calculateDate(expirationValue)
    updateElementText('#token-summary [data-token-summary="expiration"]', `on ${dateString}.`)
  }
}

function dataPermissionAttribute(permissionType: string) {
  if (permissionType === PermissionType.Organization) {
    return CheckedPermissionsSelector.Organization
  }
  if (permissionType === PermissionType.Repository) {
    return CheckedPermissionsSelector.Repository
  }
  if (permissionType === PermissionType.User) {
    return CheckedPermissionsSelector.User
  }
  if (permissionType === PermissionType.Business) {
    return CheckedPermissionsSelector.Business
  }

  return null
}

function summaryContainerSelector(permissionType: string) {
  if (permissionType === PermissionType.Organization) {
    return PermissionSummarySelector.Organization
  } else if (permissionType === PermissionType.Repository) {
    return PermissionSummarySelector.Repository
  } else if (permissionType === PermissionType.User) {
    return PermissionSummarySelector.User
  }

  return null
}

function getSummaryContainerElement(permissionType: string) {
  const selector = summaryContainerSelector(permissionType)
  if (!selector) return null

  return document.querySelector<HTMLDivElement>(selector)
}

function getSelectedPermissionElements(permissionType: string) {
  const dataSelector = dataPermissionAttribute(permissionType)
  if (!dataSelector) return []

  const elementCheckedSelector = '[aria-checked="true"]'

  return document.querySelectorAll<HTMLInputElement | HTMLButtonElement>(
    `${dataSelector}.js-permissions-selector [id^=integration_permission_]:not([data-permission="none"])${elementCheckedSelector}`,
  )
}

function getMandatoryPermissionElements(permissionType: string) {
  if (permissionType !== PermissionType.Repository) return []

  const elementCheckedSelector = '[aria-checked="true"]'

  return document.querySelectorAll<HTMLButtonElement>(
    `${CheckedPermissionsSelector.Repository}.js-permissions-selector [id^=integration_permission_][data-mandatory="true"]${elementCheckedSelector}`,
  )
}

function updatePermissionSummaryCounts(permissionType: string, selectedCount: number, mandatoryCount: number = 0) {
  // update permission count in permission label and summary if present
  updateElementText(`[data-token-summary='${permissionType}-selected-permission-count']`, String(selectedCount))
  updateElementText(`[data-token-summary='${permissionType}-mandatory-permission-count']`, String(mandatoryCount))

  const totalPermissionCount = selectedCount + mandatoryCount
  updateElementText(`[data-token-summary='${permissionType}-total-permission-count']`, String(totalPermissionCount))

  setPermissionTextPlural(permissionType, selectedCount)
}

function togglePermissionContainerLabels(permissionType: string, selectedCount: number, mandatoryCount: number = 0) {
  toggleHeaderLabel(`${dataPermissionAttribute(permissionType)} .js-permission-list-selected-label`, selectedCount)
  toggleHeaderLabel(`${dataPermissionAttribute(permissionType)} .js-permission-list-mandatory-label`, mandatoryCount)
}

function toggleHeaderLabel(labelSelector: string, count: number) {
  const labelElement = document.querySelector<HTMLElement>(labelSelector)

  if (labelElement) labelElement.hidden = count === 0
}

function setPermissionTextPlural(permissionType: string, count: number) {
  const tokenSummaryPluralSelector = `${summaryContainerSelector(permissionType)} [data-token-summary-plural]`
  if (count === 1) {
    updateElementText(tokenSummaryPluralSelector, '')
  } else {
    updateElementText(tokenSummaryPluralSelector, 's')
  }
}

function appendPermissionSummary(listElement: HTMLUListElement, permissionEl: HTMLInputElement | HTMLButtonElement) {
  const template = listElement.querySelector<HTMLLIElement>('[data-permission-template="true"]')!
  const permissionName = permissionEl.closest('.js-list-group-item')!.querySelector<HTMLElement>('strong')!.textContent

  const permissionLevel = permissionEl.querySelector('.ActionListItem-label')!.textContent!.trim()

  const item = template.cloneNode(true) as HTMLLIElement
  const nameEl = document.createElement('strong')
  const levelEl = document.createElement('strong')
  nameEl.textContent = permissionName
  levelEl.textContent = `Access: ${permissionLevel}`
  item.hidden = false
  item.setAttribute('data-permission-template', 'false')
  item.append(nameEl, levelEl)
  listElement.appendChild(item)
}

function formatDateString(date: Date): string {
  return new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })
    .format(date)
    .replace(/,(?=\s\d{4})/, '')
}

export function calculateDate(expirationValue: string): string {
  const date = new Date()
  date.setDate(date.getDate() + Number(expirationValue))
  return formatDateString(date)
}
