import {on} from 'delegated-events'

const selectedRepositories = 'VISIBILITY_SELECTED_REPOSITORIES'

export function updateVariablesSelectedRepositoryCount() {
  const selection = document.querySelector<HTMLElement>('.js-org-variables-selection')!

  const selectedPermissionInput = selection.querySelector<HTMLElement>('.js-variables-visibility-menu input:checked')!
  const currentPermission = selectedPermissionInput.getAttribute('value')!
  const repositoryAccessDialog = selection.querySelector<HTMLElement>('.js-repository-items-dialog')!
  repositoryAccessDialog.hidden = currentPermission !== selectedRepositories

  const container = selection.querySelector<HTMLElement>('.js-repository-selection-component')!
  const count = Array.from(container.querySelectorAll<HTMLInputElement>('.js-repository-item-checkbox')).filter(
    el => el.checked,
  ).length

  const selectedReposLabel = repositoryAccessDialog.querySelector<HTMLElement>('.js-selected-repos-label')!
  selectedReposLabel.hidden = currentPermission !== selectedRepositories
  selectedReposLabel.querySelector<HTMLElement>('.js-selected-repositories-count')!.textContent = count.toString()
  selectedReposLabel.querySelector<HTMLElement>('.js-multiple-repositories-text')!.hidden = count === 1
  selectedReposLabel.querySelector<HTMLElement>('.js-single-repository-text')!.hidden = count !== 1
}

on('reset', '.js-org-variables-selection .js-repository-selection-component', updateVariablesSelectedRepositoryCount)
on('details-menu-selected', '.js-variables-visibility-menu', updateVariablesSelectedRepositoryCount, {capture: true})
