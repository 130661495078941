import {on} from 'delegated-events'

on('click', '.js-used-by-selector-button', function ({currentTarget}) {
  const container = currentTarget.closest<HTMLElement>('.js-used-by-selector')!
  if (container != null) {
    const indicator = container.querySelector<HTMLElement>('.js-status-indicator')!
    if (indicator != null) {
      indicator.classList.remove('status-indicator-success', 'status-indicator-failed')
      indicator.classList.add('status-indicator-loading')
    }
  }
})
