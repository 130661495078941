import {type ColorMode, getPreferredColorMode} from '../color-modes'
import {controller, target, targets} from '@github/catalyst'

@controller
class AppearancePreviewElement extends HTMLElement {
  @target declare boxElement: HTMLElement
  @target declare activeLabel: HTMLElement
  @targets declare themePreviews: HTMLElement[]

  connectedCallback() {
    this.checkActive()
    window
      .matchMedia(`(prefers-color-scheme: ${this.getAttribute('data-mode')})`)
      .addEventListener('change', () => this.checkActive())
  }

  themePreview(e: Event) {
    const disc = e.currentTarget as HTMLElement
    if (!disc) {
      return
    }
    const theme = disc.getAttribute('data-theme')
    for (const preview of this.themePreviews) {
      preview.hidden = preview.getAttribute('data-theme') !== theme
    }
  }

  previewReset() {
    const selectedTheme = this.boxElement.querySelector('input:checked') as HTMLInputElement
    const theme = selectedTheme.value
    for (const preview of this.themePreviews) {
      preview.hidden = preview.getAttribute('data-theme') !== theme
    }
  }

  checkActive() {
    if ((this.getAttribute('data-mode') as ColorMode) === getPreferredColorMode()) {
      this.boxElement.classList.add('Box--blue')
      this.activeLabel.hidden = false
    } else {
      this.boxElement.classList.remove('Box--blue')
      this.activeLabel.hidden = true
    }
  }
}
