import {on} from 'delegated-events'

const selectedRepositories = 'VISIBILITY_SELECTED_REPOSITORIES'

export function updateSelectedRepositoryCount() {
  const selection = document.querySelector<HTMLElement>('.js-org-secrets-selection')!

  const selectedPermissionInput = selection.querySelector<HTMLElement>('.js-secrets-visibility-menu input:checked')!
  const currentPermission = selectedPermissionInput.getAttribute('value')!
  const repositoryAccessDialog = selection.querySelector<HTMLElement>('.js-repository-items-dialog')!
  repositoryAccessDialog.hidden = currentPermission !== selectedRepositories

  const container = selection.querySelector<HTMLElement>('.js-repository-selection-component')!
  const count = Array.from(container.querySelectorAll<HTMLInputElement>('.js-repository-item-checkbox')).filter(
    el => el.checked,
  ).length

  const selectedReposLabel = repositoryAccessDialog.querySelector<HTMLElement>('.js-selected-repos-label')!
  selectedReposLabel.hidden = currentPermission !== selectedRepositories
  selectedReposLabel.querySelector<HTMLElement>('.js-selected-repositories-count')!.textContent = count.toString()
  selectedReposLabel.querySelector<HTMLElement>('.js-multiple-repositories-text')!.hidden = count === 1
  selectedReposLabel.querySelector<HTMLElement>('.js-single-repository-text')!.hidden = count !== 1
}

export function enableSaveSecretButton(event: Event) {
  const container = (event.target as Element).closest<HTMLElement>('.js-encrypt-submit')!
  const button = container.querySelector<HTMLButtonElement>('.js-btn-save-secret')
  if (!button) return
  button.disabled = false
}

on('click', '.js-new-secret-value-button', function (event) {
  const button = event.currentTarget
  const formGroup = button.closest<HTMLElement>('.js-secret-value-form-group')!
  const newValueInputGroup = formGroup.querySelector<HTMLElement>('.js-new-secret-value-input')!
  newValueInputGroup.hidden = false
  newValueInputGroup.querySelector<HTMLElement>('#secret_value')!.focus()
  formGroup.querySelector<HTMLElement>('.js-new-secret-value-prompt')!.hidden = true
})

on('reset', '.js-org-secrets-selection .js-repository-selection-component', updateSelectedRepositoryCount)
on('details-menu-selected', '.js-secrets-visibility-menu', enableSaveSecretButton, {capture: true})
on('details-menu-selected', '.js-secrets-visibility-menu', updateSelectedRepositoryCount, {capture: true})
