import {remoteForm} from '@github/remote-form'

// In addition to normal .js-setting-toggle form behavior, show/hide the custom message form when the checkbox is checked
remoteForm('.js-enable-custom-msg', async function (form, wants) {
  const saveLinkForm = form.nextElementSibling as HTMLElement
  const enableCheckbox = form.querySelector<HTMLInputElement>('.js-custom-msg-checkbox')
  try {
    await wants.text()
    if (saveLinkForm) {
      saveLinkForm.hidden = enableCheckbox?.checked ? false : true
    }
  } catch {
    // .js-setting-toggle remoteForm will show error icon
  }
})
