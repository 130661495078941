import {controller, targets} from '@github/catalyst'

@controller
class PagesBuildType extends HTMLElement {
  @targets declare types: HTMLElement[]

  async changeType(type: number) {
    // if we have it checked not need to do anything
    if (this.types[type]!.hasAttribute('disabled') && this.types[type]!.hasAttribute('checked')) return

    this.setAttribute('current', this.types[type]!.getAttribute('value')!)

    this.getElementsByTagName('pages-jekyll-config')[0]!.setAttribute('hidden', 'true')

    // get the form under the pages-build-type element
    const form = this.getElementsByTagName('form')[0]!
    const input = this.types[type]!
    form.appendChild(input)
    form.submit()
  }

  buildWorkflow() {
    // we pass 0 here since the workflow input will always be the first
    // element in the types array
    this.changeType(0)
  }

  buildLegacy() {
    // pass 1 since the legacy input will always be the second element
    this.changeType(1)
  }
}

if (!window.customElements.get('pages-build-type')) {
  window.customElements.define('pages-build-type', PagesBuildType)
}
