import {remoteForm} from '@github/remote-form'

remoteForm('.js-saved-reply-delete', async function (form, wants) {
  await wants.text()

  const container = form.closest('.js-saved-reply-container')!
  const count = container.querySelectorAll('.js-saved-reply-list-item').length

  const list = container.querySelector<HTMLElement>('.js-saved-reply-list')!
  list.hidden = count <= 1 ? true : false

  const emptyContainer = container.querySelector<HTMLElement>('.js-saved-reply-empty-container')!
  if (count === 1) emptyContainer.hidden = false

  const item = form.closest('.js-saved-reply-list-item')!
  item.remove()
})
