export const RepoSelectionType = {
  None: 'none',
  All: 'all',
  Selected: 'selected',
} as const

export type RepoSelectionType = (typeof RepoSelectionType)[keyof typeof RepoSelectionType]

export const PermissionType = {
  Organization: 'organization',
  Repository: 'repository',
  User: 'user',
  Business: 'business',
} as const

export type PermissionType = (typeof PermissionType)[keyof typeof PermissionType]

export function updateElementText(selector: string, text: string) {
  const els = document.querySelectorAll<HTMLElement>(selector)
  for (const el of els) {
    el.textContent = text
  }
}

export function getCheckedSelector() {
  return '[aria-checked="true"]'
}
