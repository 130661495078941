import {controller, target} from '@github/catalyst'

@controller
class ActionsPolicyFormElement extends HTMLElement {
  @target declare specificOptions: HTMLElement
  @target declare selectRadio: HTMLInputElement
  @target declare allowlist: HTMLInputElement
  @target declare warning: HTMLElement
  @target declare wildcardWarningText: HTMLElement
  @target declare onlyBlockedWarningText: HTMLElement

  connectedCallback() {
    // In case user refreshes the page and the form saved state in Firefox
    this.toggleSpecificOptions()
    this.checkForWarnings()
  }

  toggleSpecificOptions() {
    if (this.selectRadio.checked) {
      this.specificOptions.hidden = false
    } else {
      this.specificOptions.hidden = true
    }
  }

  checkForWarnings() {
    if (!this.warning) {
      return
    }

    const patterns = this.allowlist.value
      .split(',')
      .map(p => p.trim())
      .filter(p => p !== '')

    // warn if every pattern is blocking
    if (patterns.length > 0 && patterns.every(p => p.startsWith('!'))) {
      this.onlyBlockedWarningText.hidden = false
    } else {
      this.onlyBlockedWarningText.hidden = true
    }

    // warn if there is a '*' wildcard pattern in the list
    if (patterns.length > 0 && patterns.includes('*')) {
      this.wildcardWarningText.hidden = false
    } else {
      this.wildcardWarningText.hidden = true
    }

    if (!this.onlyBlockedWarningText.hidden || !this.wildcardWarningText.hidden) {
      this.warning.hidden = false
    } else {
      this.warning.hidden = true
    }
  }
}
