import {on} from 'delegated-events'
import type {ItemActivatedEvent} from '@primer/view-components/app/components/primer/shared_events'
import type {SelectPanelElement} from '@primer/view-components/app/components/primer/alpha/select_panel_element'

function handleItemActivated(selectPanel: SelectPanelElement, event: CustomEvent<ItemActivatedEvent>) {
  if (!selectPanel) {
    throw new Error('Select panel element not provided.')
  }

  const item = event.detail.item as HTMLElement
  const autocompleteValue = item.getAttribute('data-autocomplete-value')
  if (!autocompleteValue) {
    const firstTag = item.outerHTML.match(/^<[^>]+>/)?.[0]
    const tagInfo = firstTag ? `: ${firstTag}` : ''
    throw new Error(`Autocomplete value not found on the activated item${tagInfo}`)
  }

  const repositoryName = selectPanel.getAttribute('data-repository')
  const userName = selectPanel.getAttribute('data-user-id')
  if (!repositoryName) {
    throw new Error('Repository name attribute is missing on the select panel.')
  }
  if (!userName) {
    throw new Error('User ID attribute is missing on the select panel.')
  }

  const [entityType, entityId] = autocompleteValue.split('/')
  const url = new URL(`/${userName}/${repositoryName}/invite_member`, window.location.origin)
  if (entityType && entityId) {
    url.searchParams.append('invitee_type', entityType)
    url.searchParams.append('invitee_id', entityId)
  } else {
    throw new Error('Entity type or entity ID is missing in the autocomplete value.')
  }

  window.location.href = url.toString()
}

on('itemActivated', '#add-access-dialog-user', (event: CustomEvent<ItemActivatedEvent>) => {
  const userSelectPanel = event.target as SelectPanelElement
  handleItemActivated(userSelectPanel, event)
})

on('itemActivated', '#add-access-dialog-team', (event: CustomEvent<ItemActivatedEvent>) => {
  const teamSelectPanel = event.target as SelectPanelElement
  handleItemActivated(teamSelectPanel, event)
})

function toggleHidden(els: HTMLElement[]) {
  for (const el of els) {
    el.hidden = !el.hidden
  }
}

function reenableAllRoles(inpts: NodeListOf<HTMLInputElement>) {
  for (const rInpt of inpts) {
    rInpt.disabled = false
    const roleDiv = rInpt.closest<HTMLElement>('.js-role-checkbox')!
    roleDiv.classList.remove('color-fg-muted')
  }
}

function toggleRoleBadge(badge: Element | null, state: boolean) {
  if (badge instanceof HTMLElement) {
    badge.hidden = state
  }
}

on('combobox-commit', '.js-repo-add-access-search', function (event) {
  if (!(event.target instanceof Element)) return
  const container = event.target.closest<HTMLElement>('.js-repo-add-access-search')!
  const item = event.target.closest<HTMLElement>('.js-autocomplete-item')!

  const selectLabel = container.querySelector<HTMLElement>('.js-selected-member-id')!
  selectLabel.textContent = event.target.getAttribute('data-autocomplete-label') || 'selection'

  const selectionBadge = container.querySelector<HTMLElement>('.js-selected-member-badge')!
  const itemSelectionBadge = item.querySelector<HTMLElement>('.js-selection-badge')!
  selectionBadge.innerHTML = itemSelectionBadge.innerHTML

  toggleHidden(
    Array.from(container.querySelectorAll('.js-repo-add-access-search-selected, .js-repo-add-access-search-selection')),
  )

  // Hide the base role icon by default
  const baseRoleBadge = container.querySelector('.js-base-role-badge')
  toggleRoleBadge(baseRoleBadge, true)

  // disable roles
  const memberType = item.getAttribute('data-type')
  const orgAdmin = item.hasAttribute('data-admin')

  // clear out old styling if the user navigated away from adding a user
  const inputSelector = orgAdmin ? `[data-disable-org-admin]` : `[data-disable-org-role]`
  const roleInputs = container.querySelectorAll<HTMLInputElement>(inputSelector)

  const allInputs = container.querySelectorAll<HTMLInputElement>(`input[name="role"]`)

  // Roles are not displayed on user-owned repositories,
  // Hence need for this check to guard against when roles are not displayed,
  // So Element not found error is not raised.
  if (allInputs.length > 0) {
    reenableAllRoles(allInputs)

    if (memberType === 'org-member') {
      toggleRoleBadge(baseRoleBadge, false)
      // hide all roles that are lower than default
      for (const rI of roleInputs) {
        rI.disabled = true
        const roleDiv = rI.closest<HTMLElement>('.js-role-checkbox')!
        roleDiv.classList.add('color-fg-muted')
      }
    }

    if (orgAdmin) {
      container.querySelector<HTMLInputElement>('.js-role-admin')!.checked = true
    } else if (baseRoleBadge && memberType === 'org-member') {
      const baseRoleCheckBox = baseRoleBadge
        .closest<HTMLElement>('.js-role-checkbox')!
        .querySelector<HTMLInputElement>('input[name="role"]')!
      baseRoleCheckBox.checked = true
    } else if (
      memberType === 'outside-collab' ||
      memberType === 'team' ||
      memberType === 'org-member' ||
      memberType === 'email'
    ) {
      container.querySelector<HTMLInputElement>('.js-role-pull')!.checked = true
    }
  }
})

on('click', '.js-cancel-selection', function (event) {
  if (!(event.target instanceof Element)) return
  const container = event.target.closest<HTMLElement>('.js-repo-add-access-search')!

  const input = container.querySelector<HTMLInputElement>('.js-repo-add-access-search-input')!
  input.value = ''

  const allInputs = container.querySelectorAll<HTMLInputElement>(`input[name="role"]`)
  reenableAllRoles(allInputs)

  toggleHidden(
    Array.from(container.querySelectorAll('.js-repo-add-access-search-selected, .js-repo-add-access-search-selection')),
  )
  const baseRoleBadge = container.querySelector('.js-base-role-badge')
  toggleRoleBadge(baseRoleBadge, true)
})

on('details-dialog-close', '.js-add-access-form', function ({currentTarget}) {
  const input = currentTarget.querySelector<HTMLInputElement>('.js-repo-add-access-search-input')!
  input.value = ''

  const allInputs = input.querySelectorAll<HTMLInputElement>(`input[name="role"]`)
  reenableAllRoles(allInputs)

  const selection = currentTarget.querySelector<HTMLElement>('.js-repo-add-access-search-selection')!
  const selected = currentTarget.querySelector<HTMLElement>('.js-repo-add-access-search-selected')!
  if (selected.hidden) return
  toggleHidden([selected, selection])
  const baseRoleBadge = input.querySelector('.js-base-role-badge')
  toggleRoleBadge(baseRoleBadge, true)
})
