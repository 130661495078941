import {remoteForm} from '@github/remote-form'

remoteForm('.js-user-sessions-revoke', async function (form, wants) {
  await wants.text()

  const container = form.closest<HTMLElement>('.js-user-sessions-container')!
  const item = form.closest<HTMLElement>('.js-user-session')!
  item.remove()

  if (container) {
    const count = container.querySelectorAll('.js-user-session').length
    container.classList.toggle('has-active-sessions', count > 0)
  }
})
