// Edit an Integration (GitHub App)
// The same form is used for both creating new Apps and updating existing Apps.

import {markCallbackUrlsRequired} from './integrations-callback-urls'
import {on} from 'delegated-events'

function setupUrlField() {
  return document.querySelector<HTMLInputElement>('input.js-edit-integration-setup-url')!
}

function setupUrlHint() {
  const formGroup = setupUrlField().closest<HTMLElement>('.form-group')!

  return formGroup.querySelector<HTMLElement>('.js-setup-url-hint')!
}

function helpTextForRedirectOnUpdate() {
  return document.querySelector<HTMLElement>('.js-help-text-for-redirect-on-update')!
}

function updateSetupUrlHint(value: string) {
  if (value) setupUrlHint().textContent = value
}

on('change', '.js-edit-integration-request-oauth-on-install', function (event) {
  const target = event.target as HTMLInputElement
  const urlField = setupUrlField()

  if (target.checked) {
    markCallbackUrlsRequired(true)
    urlField.disabled = true
    urlField.placeholder = urlField.getAttribute('data-hint-unavailable-when-install') || ''

    const hintUrl = urlField.getAttribute('data-hint-same-as-callback-url') || ''
    updateSetupUrlHint(hintUrl)

    const redirectOnUpdateHint = helpTextForRedirectOnUpdate()
    redirectOnUpdateHint.textContent =
      redirectOnUpdateHint.getAttribute('data-hint-redirect-on-update-callback-url') || ''
  } else {
    markCallbackUrlsRequired(false)
    urlField.disabled = false
    urlField.placeholder = ''
    urlField.value = urlField.getAttribute('data-setup-url') || ''

    const hintUrl = urlField.getAttribute('data-hint-setup-url') || ''
    updateSetupUrlHint(hintUrl)

    const redirectOnUpdateHint = helpTextForRedirectOnUpdate()
    redirectOnUpdateHint.textContent = redirectOnUpdateHint.getAttribute('data-hint-redirect-on-update-setup-url') || ''
  }
})
