import {controller, target} from '@github/catalyst'
import {parseHTML} from '@github-ui/parse-html'

@controller
class DefaultLocationSettingElement extends HTMLElement {
  @target declare manualRadioButton: HTMLInputElement
  @target declare regionSelect: HTMLInputElement
  @target declare locationForm: HTMLFormElement
  @target declare autoRadioButton: HTMLInputElement

  async changeManuallySelected() {
    if (this.manualRadioButton.checked) {
      this.regionSelect.disabled = false
      if (this.regionSelect.value !== '') {
        await this.regionSelected()
      }
    }
  }

  async changeAutoSelected() {
    if (this.autoRadioButton.checked) {
      this.regionSelect.disabled = true
      await this.submitSelectedRegion(null)
    }
  }

  async regionSelected() {
    const selectedRegion = this.regionSelect.value
    await this.submitSelectedRegion(selectedRegion)
  }

  async submitSelectedRegion(region: string | null) {
    const data = new FormData(this.locationForm)
    if (region !== null) {
      data.append('codespace_default_location', region)
    }
    const response = await fetch(this.locationForm.action, {
      method: this.locationForm.method,
      body: data,
      headers: {
        Accept: 'text/fragment+html',
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
    const html = parseHTML(document, await response.text())
    const settingContainer = this.closest('.js-default-location-setting-container')!
    settingContainer.replaceWith(html)
  }
}
