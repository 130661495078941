import {changeValue} from '@github-ui/form-utils'

import {remoteForm} from '@github/remote-form'
import {resetUserAddForm} from './user-add'

remoteForm('.js-remote-submit', async function (form, wants) {
  const response = await wants.html()

  const group = document.querySelector<HTMLElement>('.js-user-block-settings-list')!
  const list = group.querySelector<HTMLElement>('.js-blocked-list')!
  const blank = group.querySelector<HTMLElement>('.js-user-block-blank-slate')!

  resetUserAddForm(form, false)
  const input = form.querySelector<HTMLInputElement>('.js-add-blocked-user-field')!
  changeValue(input, '')

  blank.hidden = true
  list.hidden = false
  list.prepend(response.html)
})
