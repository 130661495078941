import {on} from 'delegated-events'
import {updateExpiration} from './personal-access-token-summary'

function updateExpirationNoteAndCustomDateSelector(humanText: string | null, selectedValue: string | null) {
  const pickerWrapper = document.getElementById('custom-expires-wrapper') as HTMLElement
  const picker = document.querySelector('#custom-expires-wrapper .form-select') as HTMLInputElement
  const formElementWrapper = document.getElementById('token-expiration') as HTMLElement
  const expirationWarning = document.getElementById('no-expiration-warning') as HTMLElement
  const tokenWillExpireOnDateMessage = document.getElementById('token-expiration-date-message') as HTMLElement

  if (selectedValue && picker) {
    tokenWillExpireOnDateMessage?.removeAttribute('hidden')
    expirationWarning.setAttribute('hidden', 'hidden')
    if (selectedValue === 'custom') {
      pickerWrapper.removeAttribute('hidden')
    } else {
      pickerWrapper.setAttribute('hidden', 'hidden')
      picker.value = ''
      formElementWrapper.classList.remove('errored')

      if (selectedValue === 'none') {
        expirationWarning.removeAttribute('hidden')
        tokenWillExpireOnDateMessage?.setAttribute('hidden', 'hidden')
      }
    }
  }
}

function resetTokenExpirationSelect() {
  const template = document.querySelector('.js-new-default-token-expiration-select-template') as HTMLElement
  if (!template) return

  const selectContainer = document.querySelector('.js-new-default-token-expiration-select-container') as HTMLElement

  selectContainer.innerHTML = template.innerHTML
}

function resetExemptionBanner() {
  const container = document.querySelector('#expiration-limit-exemption-banner-container') as HTMLElement
  const bannerContent = document.querySelector('#expiration-limit-exemption-banner-content') as HTMLElement

  if (container) {
    container.setAttribute('hidden', 'hidden')
  }

  if (bannerContent) {
    bannerContent.textContent = ''
  }
}

function updateTokenExpirationField(expirationLimit: number, label: string | null, target: string | null) {
  removeItemsOverLimit(expirationLimit)
  updateCustomOptionDescription(expirationLimit, label, target)
}

function updateExemptionBanner(expirationLimit: number, expirationLimitTarget: string) {
  const container = document.querySelector('#expiration-limit-exemption-banner-container') as HTMLElement
  const bannerContent = document.querySelector('#expiration-limit-exemption-banner-content') as HTMLElement

  if (container && bannerContent) {
    // eslint-disable-next-line i18n-text/no-en
    const bannerInnerHTML = `The ${expirationLimitTarget} requires a maximum lifetime of ${expirationLimit} day${
      expirationLimit !== 1 ? 's' : ''
    } for fine-grained personal access tokens. As an enterprise administrator you are <strong>exempted</strong> from this requirement.`
    bannerContent.innerHTML = bannerInnerHTML

    container.removeAttribute('hidden')
  }
}

function removeItemsOverLimit(expirationLimit: number) {
  for (const item of document.querySelectorAll('.js-new-default-token-expiration-item')) {
    const itemValue = item.querySelector('button')?.getAttribute('data-value')
    if (!itemValue) continue

    if (itemValue === 'custom' && expirationLimit === 1) {
      // remove the custom field if the expiration limit is 1 day
      document
        .querySelector<HTMLButtonElement>(".js-new-default-token-expiration-item > button[data-value='custom']")
        ?.setAttribute('hidden', 'hidden')
      continue
    }

    // reset previously hidden items
    item.removeAttribute('hidden')

    // Hide items that are over the limit
    if (expirationLimit > 0 && Number(itemValue) > expirationLimit) {
      item.setAttribute('hidden', 'hidden')
    }

    // Hide the "No Expiration" option if expiration limit is set
    if (expirationLimit > 0 && itemValue === 'none') {
      item.setAttribute('hidden', 'hidden')
    }
  }
}

function updateCustomOptionDescription(expirationLimit: number, label: string | null, target: string | null) {
  if (expirationLimit > 0) {
    // update the custom max date option
    const date = new Date()
    date.setDate(date.getDate() + expirationLimit)
    const newCustomDateMaxDate = date.toISOString().split('T')[0] || ''
    const customDateField = document.querySelector<HTMLInputElement>('#user_programmatic_access_custom_expires_at')
    customDateField?.setAttribute('max', newCustomDateMaxDate)

    // is there a ready item that matches the limit?
    const item = document.querySelector(
      `.js-new-default-token-expiration-item > button[data-value='${expirationLimit}']`,
    )

    if (item) {
      const description = item.querySelector('.ActionListItem-description')

      if (description) {
        // eslint-disable-next-line i18n-text/no-en
        description.textContent = `Limit set by ${target}`
      }
    } else {
      // if there isn't, we will get the blank custom limit option and set the description and value to the limit

      const newCustomLimitItem = document.querySelector<HTMLElement>(
        '.js-new-default-token-expiration-item-custom-limit',
      )
      if (newCustomLimitItem) {
        newCustomLimitItem.removeAttribute('hidden')
        newCustomLimitItem.querySelector('button')?.setAttribute('data-value', `${expirationLimit}`)
        const limitItemLabel = newCustomLimitItem.querySelector('.ActionListItem-label')
        if (limitItemLabel) {
          limitItemLabel.textContent = label
        }
        const description = newCustomLimitItem.querySelector('.ActionListItem-description')

        if (description) {
          // eslint-disable-next-line i18n-text/no-en
          description.textContent = `Limit set by ${target}`
        }
      }
    }

    if (expirationLimit < 30) {
      const button = document.querySelector<HTMLButtonElement>(
        `.js-new-default-token-expiration-item > button[data-value='${expirationLimit}']`,
      )
      if (button) {
        button.click()
      }
    }

    // set the description for the custom option
    const customOptionDescription = document.querySelector(
      '.js-new-default-token-expiration-item > button[data-value="custom"] .ActionListItem-description',
    )
    if (customOptionDescription) customOptionDescription.textContent = `between 1 and ${expirationLimit} days`
  }
}

const syncResourceOwner = (event: CustomEvent) => {
  if (!(event.target instanceof Element)) return

  const {item: selectedItem} = event.detail

  updateShowButtonLabel(selectedItem)
}

const updateShowButtonLabel = (selectedItem: HTMLLIElement) => {
  selectedItem.setAttribute('aria-selected', 'true')
  const showButton = document.querySelector('#resource-owner-select-panel-button')
  const showButtonLabel = showButton?.querySelector('.Button-label')
  if (!(showButtonLabel instanceof HTMLSpanElement)) return

  for (const child of showButtonLabel.childNodes) {
    if (child.nodeName !== 'IMG') child.remove()
  }

  const showButtonImg = showButtonLabel.querySelector('img')
  const selectedItemImg = selectedItem.querySelector('#resource-owner-select-panel-item-icon') as HTMLImageElement

  if (showButtonImg) {
    showButtonImg.setAttribute('src', selectedItemImg.src)
    showButtonImg.classList.toggle('circle', selectedItem.getAttribute('data-actor-is-organization') !== 'true')
  }

  const textNode = document.createTextNode(selectedItem.textContent || '')
  showButtonLabel.appendChild(textNode)
}

const updateExpirationUI = (event: CustomEvent) => {
  resetTokenExpirationSelect()
  resetExemptionBanner()

  const relatedTarget = event.detail.item as HTMLLIElement

  const expirationLimit = relatedTarget.getAttribute('data-fg-limit')
  const expirationLimitLabel = relatedTarget.getAttribute('data-fg-limit-label')
  const expirationLimitTarget = relatedTarget.getAttribute('data-fg-limit-target')
  const expirationExempted = relatedTarget.getAttribute('data-fg-limit-exempt')
  if (expirationExempted === 'false')
    updateTokenExpirationField(Number(expirationLimit), expirationLimitLabel, expirationLimitTarget)
  if (!expirationLimitTarget || !expirationLimit) return

  if (expirationExempted === 'true') updateExemptionBanner(Number(expirationLimit), expirationLimitTarget)
}

on(
  'itemActivated',
  '.js-new-default-token-expiration-select',
  function (event) {
    const item = event.detail.item as HTMLElement

    const itemValue = item.querySelector('button')?.getAttribute('data-value') || null
    if (!itemValue) return
    const humanText = item.getAttribute('data-human-date')

    updateExpirationNoteAndCustomDateSelector(humanText, itemValue)
    updateExpiration()
  },
  {capture: true},
)

on('details-menu-selected', '.js-target-container', updateExpirationUI, {capture: true})

on('itemActivated', '.js-target-container', updateExpirationUI, {capture: true})

on('beforeItemActivated', '#resource-owner-select-panel', (event: CustomEvent) => {
  if (!(event.target instanceof Element)) return

  const {item: selectedItem} = event.detail
  const previouslySelectedItem = event.target.querySelector('li[aria-selected="true"]')

  if (previouslySelectedItem) {
    previouslySelectedItem.setAttribute('aria-selected', 'false')
  }

  if (selectedItem) {
    selectedItem.setAttribute('aria-selected', 'true')
  }
})

on('itemActivated', '#resource-owner-select-panel', syncResourceOwner)

document.addEventListener('turbo:frame-load', function () {
  const dialog = document.getElementById('confirm-fg-pat') as HTMLDialogElement
  if (dialog) {
    dialog.showModal()
  }
})
